@use '../../variables';

.main_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 60%;
    margin-left: 20%;

    img {
        width: 100%;
        height: auto;
        opacity: 1;
        transition: opacity 1s ease-in-out;
    }

    h2,
    ul,
    li,
    p {
        margin-left: 10%;
    }

}