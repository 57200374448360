@use '../../../variables';


.footer_container {
    background: variables.$spizarnia24_footer_background_color;
    color: variables.$basic_grey_color;
    display: flex;
    flex-direction: column;
    font-family: variables.$font-family;
    width: 100vw;
    justify-content: center;
    align-items: center;

    p,
    a {
        font-size: 15px;
    }

    .columns {
        width: 70%;
        display: flex;
        justify-content: center;

        .footer_section {
            width: 25%;
            margin: 2%;
            display: flex;
            flex-direction: column;

            a {
                text-decoration: none;
                color: variables.$basic_white_color;
            }

            img {
                margin-left: 5px;
            }
        }
    }

    .second_row {
        height: 70px;
        width: 100%;
        background-color: black;
        display: flex;
        justify-content: center;

        .copyright {
            display: flex;
            justify-content: space-between;
            height: 100%;
            width: 60%;

            .left {
                width: 60%;
                height: 100%;
                display: flex;
                align-items: center;

                p {
                    color: variables.$basic_grey_color;
                }
            }

            .right {
                width: 20%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                img {
                    height: 30px;
                }
            }
        }
    }
}

a {
    text-decoration: none;
    color: variables.$basic_white_color;
}


@media screen and (max-width: variables.$first_breakpoint) {
    .footer_container {
        .columns {
            width: 100%;
        }
    }
}

@media screen and (max-width: variables.$second_breakpoint) {
    .footer_container {
        width: 100%;
    }

}

@media screen and (max-width: variables.$third_breakpoint) {
    .footer_container {
        .columns {
            width: 100%;
            flex-wrap: wrap;

            .footer_section {
                min-width: 40%;
            }
        }
    }
}


@media screen and (max-width: variables.$fourth_breakpoint) {
    .footer_container {
        .columns {
            width: 100%;
            padding: 5%;
            flex-direction: column;

            .footer_section {
                width: 90%;
                padding: 5%;
            }
        }

        .second_row {
            width: 100%;
            height: 100%;

            .copyright {
                flex-direction: column;
                width: 100%;
                height: 100%;
                padding: 2%;

                .left {
                    width: 100%;
                    height: 50%;
                }

                .right {
                    width: 100%;
                    height: 50%;
                    justify-content: flex-start;
                }
            }

        }
    }

}